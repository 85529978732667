import WaitingListWebRepository from '@/shared/http/repositories/socialProject/waitinglistweb.js'
import DeficiencyTypeRepository from '@/shared/http/repositories/socialProject/deficiency-type'
import AddressSuggestionRepository from '@/shared/http/repositories/core/address-suggestion.js'
import CidRepository from '@/shared/http/repositories/socialProject/cid.js'
import CityRepository from '@/shared/http/repositories/core/city.js'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import WaitingListWeb from '@/shared/models/waitinglistweb/waitinglistweb.js'
import SchoolTerms from '@/shared/enums/schoolTerms'
import Genders from '@/shared/enums/gender.js'
import YesNo from '@/shared/enums/yesNo.js'
import moment from 'moment'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFilter } from '@/shared/filters/ToDate'
import { dateHourFormattedFilter } from '@/shared/filters/ToDateFormatted'
import Roles from '@/shared/mixins/hasRoles'
import TableContact from '../components/contacts/TableContact.vue'
import TableLocalModality from '../components/localModality/TableLocalModality.vue'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [TableContact.name]: TableContact,
    [TableLocalModality.name]: TableLocalModality
  },

  mixins: [Roles],

  data: () => ({
    dateFormatted: null,
    waitinglist: {},
    cid: {},
    deficiencyTypes: [],
    schoolTerms: Object.values(SchoolTerms),
    genders: Object.values(Genders),
    yesNo: Object.values(YesNo)
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Lista de espera web',
            href: this.$router.resolve({ name: 'WaitingListWeb' }).href
          },
          {
            text: 'Editar lista de espera web',
            active: true
          }
        ]
      }
    },

    formatDateRegistration() {
      const result = dateHourFormattedFilter(this.waitinglist.registrationDate)

      if (result == '-') return moment().format("DD/MM/YYYY")

      return result
    }
  },

  created() {
    this.getAllDeficiencyTypes()
    this.getById()
  },

  methods: {
    getById() {
      loading.push()
      WaitingListWebRepository.GetById(this.$route.params.id)
        .then(res => {
          if(!IsSuccessCode(res)) return Promise.reject()

          this.waitinglist = new WaitingListWeb(res.data.data)
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar os dados da criança', 'ERRO')
        })
    },

    getAllDeficiencyTypes () {
      loading.push()
      DeficiencyTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.deficiencyTypes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de deficiência', 'ERRO')
        })
    },

    getCid() {
      loading.push()
      CidRepository.GetByCidCode(this.waitinglist.cidCode)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const cid = res.data.data
          this.waitinglist.cidCode = cid.cidCode
          this.waitinglist.cidDescription = cid.name

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar CID', 'ERRO')
        })
    },

    getAddress() {
      loading.push()
      AddressSuggestionRepository.GetByZipCode(this.waitinglist.address.zipCode)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const address = res.data.data
          this.waitinglist.address.street = address.street
          this.waitinglist.address.district = address.neighborhood

          this.waitinglist.address.cityId = address.cityID
          this.getCityById(this.waitinglist.address.cityId)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar endereço pelo cep', 'ERRO')
        })
    },

    getCityById(cityId) {
      loading.push()
      CityRepository.GetById(cityId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.waitinglist.address.cityName = res.data.data.name
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar cidades', 'ERRO')
        })
    },

    onSave() {
      const wl = new WaitingListWeb(this.waitinglist)
      wl.address.status = 1
      wl.contacts.forEach(contact => {
        contact.status = 1
      })
      wl.localTrainingModalities.forEach(lm => {
        lm.status = 1
      })

      loading.push()
      WaitingListWebRepository.Update(wl)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject(res.data.resultException)

          toast.success('Registro atualizado com sucesso', 'EDITAR REGISTRO')
          loading.pop()
          Promise.resolve()
          return this.$router.replace({ name: 'WaitingListWeb' })
        })
        .catch(e => {
          loading.pop()
          if (e.Message === 'Student already saved') {
            toast.error('CPF já cadastrado', 'ERRO')
          } else {
            toast.error('Erro ao atualizar registro na lista de espera', 'ERRO')
          }
        })
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    onDateContext(ctx) {
      this.dateFormatted = ctx.selectedFormatted
    },

    formatDate(event) {
      this.student.birthDate = dateFilter(event.currentTarget.value)
    },

    backTo() {
      this.$router.go(-1)
    }
  }
}
