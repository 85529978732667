import GuidUtils from '@/shared/utils/guid'

export default class WaitingListWeb {
  constructor({
    id,
    name,
    gender,
    rg,
    cpf,
    birthDate,
    schoolTerm,
    hasDeficiency,
    deficiencyTypeId,
    registrationDate,
    observations,
    cidId,
    cidCode,
    cidDescription,
    address,
    contacts,
    localTrainingModalities,
    status
  } = {}) {
    this.id = id || GuidUtils.emptyGuid
    this.name = name
    this.gender = gender
    this.rg = rg
    this.cpf = cpf
    this.birthDate = birthDate
    this.schoolTerm = schoolTerm
    this.hasDeficiency = hasDeficiency ? 1 : 0
    this.deficiencyTypeId = deficiencyTypeId
    this.observations = observations
    this.registrationDate = registrationDate
    this.cidId = cidId
    this.cidCode = cidCode
    this.cidDescription = cidDescription
    this.address = address
    this.contacts = contacts
    this.localTrainingModalities = localTrainingModalities
    this.status = status
  }
}
